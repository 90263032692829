import React, { useContext } from 'react'
import { css } from 'emotion'
import { FView, FText } from 'f-web-app/components'
import { useMediaMinLG } from 'f-web/src/hooks'
import { ThemeContext } from 'f-web-app'

export default function CategoryItemView({ index, imageUrl, isSelected, name }) {
  const { themeColors, themeImages } = useContext(ThemeContext)
  const isMinLG = useMediaMinLG()
  return (
    <FView className={classes.container}>
      <FView
        ml={index % 2 === 0 ? 16 : 8}
        mr={index % 2 === 0 ? 8 : 16}
        relative
        className={classes.imageContainer}
        mb={5}
      >
        {imageUrl ? (
          <img className={classes.image} src={imageUrl} alt="Category" />
        ) : (
          <img className={classes.iconImage} src={themeImages.icon} alt="Category" />
        )}
        {isMinLG && (
          <FView
            className={classes.animate}
            absolute
            h="37vw"
            w={'100%'}
            maxHeight={135}
            bg={themeColors.black25}
            opacity={isSelected ? 0 : 1}
          />
        )}
      </FView>
      <FText
        h7
        heading
        bold
        color={isMinLG && isSelected ? themeColors.primary : themeColors.backgroundText}
        textCenter
      >
        {name ? name.toUpperCase() : ''}
      </FText>
    </FView>
  )
}

const classes = {
  container: css({
    cursor: 'pointer',
    height: '50vw',
    width: '50vw',
    maxHeight: 190,
    maxWidth: 200,
    '&:hover': {
      transform: 'translateY(-4px)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
  }),
  imageContainer: css({
    height: '37vw',
    width: 'calc(100% - 24px)',
    maxHeight: 135,
    overflow: 'hidden',
  }),
  image: css({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }),
  iconImage: css({
    width: '100%',
    height: '100%',
    padding: 10,
    objectFit: 'contain',
  }),
  animate: css({
    transition: 'opacity .5s cubic-bezier(.19,1,.4,1)',
  }),
}
