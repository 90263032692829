import { COLOR as fColors } from 'f-web-app/styles'

export * from 'f-web/src/styles'

export const COLOR = {
  ...fColors,
  background: '#252525',
  backgroundText: '#FFF',
  primaryText: '#FFF',
  cardText: '#010B19',
  backgroundImageText: '#010B19',

  primary: 'rgba(208, 2, 27, 1)',
  primary75: 'rgba(208, 2, 27, .75)',
  primary50: 'rgba(208, 2, 27, .50)',
  primary25: 'rgba(208, 2, 27, .25)',
  primary10: 'rgba(208, 2, 27, .10)',

  secondary: 'rgba(154, 28, 2, 1)',
  secondary75: 'rgba(154, 28, 2, .75)',
  secondary50: 'rgba(154, 28, 2, .50)',
  secondary25: 'rgba(154, 28, 2, .25)',
  secondary10: 'rgba(154, 28, 2, .10)',
}

export const TEXT = {
  heading: {
    fontFamily: 'Archivo',
  },
}
