import React, { useContext } from 'react'
import { FView, FText } from 'components'
import { useMediaMinLG } from 'f-web/src/hooks'
import { ThemeContext } from 'f-web-app'

function SectionLabel({ label, color }) {
  const { themeColors } = useContext(ThemeContext)
  const isMinLG = useMediaMinLG()

  return (
    <FView mb={isMinLG ? '5rem' : '3rem'}>
      <FView h={5} w={isMinLG ? 200 : 120} bg={themeColors.primary} />
      <FText h4 h2={isMinLG} backgroundText bold style={{ zIndex: 1 }}>
        {label}
      </FText>
      {/* <img
        src={themeImages.titleUnderline}
        style={{ width: isMinLG ? 325 : 185, objectFit: 'contain', marginTop: -8 }}
        alt=""
      /> */}
    </FView>
  )
}

export default function HomeView({ locationId }) {
  const isMinLG = useMediaMinLG()
  const { Home, Components, themeColors, themeImages } = useContext(ThemeContext)
  return (
    <FView bg={themeColors.background} overflowX={'hidden'}>
      <Components.PageHeader />
      <Home.Hero />
      <FView size={isMinLG ? '10rem' : '5rem'} />
      <FView mh={15} ph={15}>
        <FView selfCenter w={1024} maxWidth={'100%'}>
          <SectionLabel label={`Our Restaurant`} />
          <FView row={isMinLG} alignCenter>
            <img
              src={themeImages.aboutUs}
              style={{ objectFit: 'contain', maxHeight: '73vw', maxWidth: '100%', zIndex: 2 }}
              alt="our restaurant"
            />
            <FView size={isMinLG ? 50 : 15} />
            <FText
              backgroundText
              h7
              h5={isMinLG}
              textCenter={!isMinLG}
              lineHeight={1.3}
              style={{ maxWidth: 374, zIndex: 98 }}
            >
              We share our passion for authentic Korean food by serving unique dishes made with high quality
              ingredients. Come visit us at our restaurant in Lougheed to taste one of our delicious dishes in a fun and
              welcoming atmosphere.
            </FText>
          </FView>
        </FView>
        <FView absolute right={0}>
          <img src={themeImages.aboutUsBG} alt="about us bg" style={isMinLG ? null : { display: 'none' }} />
        </FView>
      </FView>
      <FView size={isMinLG ? '10rem' : '5rem'} />
      <FView mh={15} ph={15}>
        <FView absolute left={0} mt={'71em'}>
          <img src={themeImages.onlineOrdersBG} alt="about us bg" style={isMinLG ? null : { display: 'none' }} />
        </FView>
        <FView selfCenter w={1024} maxWidth={'100%'} mh={isMinLG ? 0 : 25} relative>
          <SectionLabel label={'Featured Items'} />
          <FView row w={1024} maxWidth={'100%'}>
            <img
              src={themeImages.feature1}
              alt="featured item"
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </FView>
          <FView mh={isMinLG ? 0 : '1.5rem'}>
            <FView selfCenter w={1340} maxWidth={'100%'}>
              <FView size={isMinLG ? '5rem' : '2.5rem'} />
              <FView selfCenter w={1024} maxWidth={'100%'}>
                <FText backgroundText h7 h4={isMinLG} bold textCenter>
                  Korean Style Seafood & Sushi
                </FText>
                <FView size={'1.5rem'} />
                <FText backgroundText h7 h5={isMinLG} textCenter lineHeight={1.3} style={{ zIndex: 3 }}>
                  We proudly serve BBQ and Korean style seafood all in one sitting. Our dishes are made only with fresh
                  ingredients and we take pride that our food tastes great and authentic. We strongly recommend that you
                  try one of our special menu items, such as the Spicy Stir-fried Pork Skin, to taste the authentic
                  Korean cuisine.
                </FText>
              </FView>
            </FView>
          </FView>
        </FView>
      </FView>
      <FView size={isMinLG ? '10rem' : '5rem'} />

      <FView size={isMinLG ? '10rem' : '5rem'} />

      <FView mh={15} ph={15}>
        <FView selfCenter w={1024} maxWidth={'100%'} mh={15} pr={100}>
          <SectionLabel label={`Now Taking Online Orders`} />
        </FView>
        <FView selfCenter w={1024} maxWidth={'100%'}>
          <img
            src={themeImages.mobilePromo}
            alt="mobile order promotion"
            style={{ objectFit: 'contain', width: '100%', zIndex: 1 }}
          />
          <FText backgroundText h7 h5={isMinLG} textCenter>
            Order online by clicking Order Pickup or Order Delivery at the top of the website.
          </FText>
        </FView>
      </FView>

      <FView size={'5rem'} />

      <FView mh={15} ph={15}>
        <FView h={1} bg={themeColors.lightGrey} />
        <FView size={isMinLG ? '5rem' : '2.5rem'} />
        <FView row={isMinLG} selfCenter w={isMinLG ? 1024 : 400} maxWidth={'100%'} justifyCenter alignStart={isMinLG}>
          <img src={themeImages.deliveryLabel} style={{ objectFit: 'contain' }} alt="delivery label" />
          <FView size={15} />
          <FText bold={!isMinLG} h8 h5={isMinLG} textCenter={!isMinLG} backgroundText>
            We deliver within 5.5 km radius.
          </FText>
        </FView>
        <FView size={isMinLG ? '5rem' : '2.5rem'} />
        <Home.ContactUs
          appStoreUrl={'https://apps.apple.com/us/app/id1475070939'}
          googlePlayUrl="https://play.google.com/store/apps/details?id=tech.foodly.jejudo"
        />
      </FView>
      <FView size={'2vw'} maxSize={72} />
      <Home.Footer />
    </FView>
  )
}
