import React, { useContext } from 'react'
import { css } from 'emotion'
import { useMediaMinMD, useMediaMinLG } from 'f-web/src/hooks'
import { FView, FText, FButton } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

export default function Hero() {
  const { Components, themeImages } = useContext(ThemeContext)
  const isMinLG = useMediaMinLG()
  const isMinMD = useMediaMinMD()
  return (
    <FView
      className={css(classes.container, {
        background: `url(${themeImages.heroBG}) no-repeat`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      })}
      center
      mt={isMinLG ? 0 : 100}
    >
      <FView style={isMinMD ? { position: 'absolute', top: '150px' } : null}>
        <FView w={isMinMD ? 550 : 300}>
          {/* <img src={themeImages.heroLogo} alt="hero logo" style={{ objectFit: 'contain', width: isMinMD ? 340 : 186 }} /> */}
          <FText heading white h4 h3={isMinMD} h2={isMinLG} bold textCenter>
            Korean Seafood & Stir-Fried Dishes
          </FText>
        </FView>
        <FView h={'2.5rem'} />
        <FView row={isMinMD} style={{ alignItems: 'center' }}>
          <FButton
            onClick={() =>
              (window.location.href = 'https://foodly.ca/direct/order/jejudo/fvmVGyqAVbUmxMNnKe8z/56DyYBHlc8mU0em1NM6c')
            }
          >
            <Components.PaintedButtonView label="Order Pickup" />
          </FButton>
          <FView size={'1.5rem'} />
          <FButton
            onClick={() =>
              (window.location.href = 'https://foodly.ca/direct/order/jejudo/fvmVGyqAVbUmxMNnKe8z/56DyYBHlc8mU0em1NM6c')
            }
          >
            <Components.PaintedButtonView label="Order Delivery" />
          </FButton>
        </FView>
      </FView>
    </FView>
  )
}

const classes = {
  container: css({
    alignSelf: 'center',
    minHeight: 280,
    height: '100vw',
    maxHeight: '66.7vw',
    width: '100%',
    position: 'relative',
  }),
}
