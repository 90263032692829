import React, { useContext } from 'react'
import { FModal, FButton, FView } from 'f-web-app/components'
import { isEmpty } from 'lodash'
import { ThemeContext } from 'f-web-app'
import { MdClose } from 'react-icons/md'
import * as utils from 'f-utils'

export default function OrderDetailsModal({ isOpen, onRequestClose, order }) {
  const { themeColors, User, Components } = useContext(ThemeContext)
  const {
    orderNumber,
    products,
    orderCartItems,
    id,
    subTotal,
    taxAmount,
    tipAmount,
    deliveryFee,
    rewards = {},
    addedCharges,
    total,
  } = order
  if (!products && !orderCartItems) {
    return null
  }
  return (
    <FModal isOpen={isOpen} closeModal={onRequestClose}>
      <User.OrderDetailsView
        orderNumber={orderNumber}
        CloseButtonElement={
          <FButton onClick={onRequestClose}>
            <FView size={'4rem'} center>
              <MdClose color={themeColors.backgroundText} size={30} />
            </FView>
          </FButton>
        }
        OrderStatusElement={<Components.OrderStatus orderId={id} />}
        ProductListElement={Object.entries(products || orderCartItems).map(
          ([cartItemId, { name, count, price, selectedModifierGroupsWithDetails }]) => {
            return (
              <User.ProductRowView
                key={cartItemId}
                count={count}
                priceFormatted={
                  price === 0
                    ? 'FREE'
                    : utils.getCartItemTotal({ count, price, selectedModifierGroupsWithDetails }).toFixed(2)
                }
                name={name}
                modifierItems={
                  !!selectedModifierGroupsWithDetails &&
                  Object.values(selectedModifierGroupsWithDetails).reduce((prev, modifierGroupDetails) => {
                    if (modifierGroupDetails) {
                      for (const [itemId, isSelected] of Object.entries(
                        modifierGroupDetails.selectedModifierItemsObj
                      )) {
                        if (
                          modifierGroupDetails.modifierItems &&
                          modifierGroupDetails.modifierItems[itemId] &&
                          modifierGroupDetails.modifierItems[itemId].defaultValue !== isSelected
                        ) {
                          prev[itemId] = {
                            ...modifierGroupDetails.modifierItems[itemId],
                            isSelected,
                          }
                        }
                      }
                    }
                    return prev
                  }, {})
                }
              />
            )
          }
        )}
        RewardListElement={
          !isEmpty(rewards) &&
          Object.values(rewards).map(reward => {
            const { name, count, totalDiscountSubtotal } = reward
            return (
              <User.RewardRowView
                key={name}
                count={count}
                name={name}
                totalDiscountSubtotal={totalDiscountSubtotal}
                priceFormatted={totalDiscountSubtotal ? totalDiscountSubtotal.toFixed(2) : 0}
              />
            )
          })
        }
        // DiscountElement={
        //   !isEmpty(rewards) &&
        //   Object.values(rewards).map(reward => {
        //     const { name, count, totalDiscountSubtotal } = reward
        //     if (totalDiscountSubtotal) {
        //       return (
        //         <User.DiscountRowView
        //           key={name}
        //           count={count}
        //           name={name}
        //           priceFormatted={totalDiscountSubtotal.toFixed(2)}
        //         />
        //       )
        //     }
        //     return null
        //   })
        // }
        AddedChargesElement={
          !!addedCharges &&
          addedCharges.map(charge => (
            <User.AddedChargeRowView key={charge.name} name={charge.name} priceFormatted={charge.price.toFixed(2)} />
          ))
        }
        subTotalFormatted={subTotal.toFixed(2)}
        deliveryFee={deliveryFee}
        taxFormatted={taxAmount.toFixed(2)}
        tipAmount={tipAmount}
        totalFormatted={total.toFixed(2)}
      />
    </FModal>
  )
}
