import React from 'react'
import { FView, FText } from 'f-web-app/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function BackButtonView() {
  return (
    <FView h={50} w="100%" justifyCenter>
      <FText backgroundText h7>
        <FontAwesomeIcon icon="chevron-left" style={{ marginRight: 10 }} />
        Back
      </FText>
    </FView>
  )
}
