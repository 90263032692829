import React, { useContext } from 'react'
import { css } from 'emotion'
import { FText, Space, Spinner } from 'f-web-app/components'
import { MEDIA_QUERY } from 'f-web-app/styles'
import { ThemeContext } from 'f-web-app'
import { useMediaMinSM } from 'f-web/src/hooks'

const OrderStatusView = ({ loading, status, isFoodReady, orderType }) => {
  const { themeColors } = useContext(ThemeContext)
  const isMinSM = useMediaMinSM()
  if (loading) {
    return (
      <div className={classes.container}>
        <Spinner color={themeColors.backgroundText} />
      </div>
    )
  }
  return (
    <div className={classes.container}>
      <div
        className={classes.status}
        style={
          status === 'New' || status === 'Preparing' || status === 'Ready' || status === 'Done'
            ? { backgroundColor: themeColors.primary }
            : { backgroundColor: themeColors.lightGrey }
        }
      >
        <FText h8 h6={isMinSM} primaryText bold>
          New
        </FText>
      </div>
      <Space w2 />
      <div
        className={classes.status}
        style={
          status === 'Preparing' || status === 'Ready' || status === 'Done'
            ? { backgroundColor: themeColors.primary }
            : { backgroundColor: themeColors.lightGrey }
        }
      >
        <FText h8 h6={isMinSM} primaryText bold>
          Prep
        </FText>
      </div>
      <Space w2 />
      <div
        className={classes.status}
        style={
          status === 'Ready' || isFoodReady || status === 'Done'
            ? { backgroundColor: themeColors.primary }
            : { backgroundColor: themeColors.lightGrey }
        }
      >
        <FText h8 h6={isMinSM} primaryText bold>
          {orderType === 'Delivery' ? 'Out' : 'Ready'}
        </FText>
      </div>
      <Space w2 />
      <div
        className={classes.status}
        style={
          status === 'Done' ? { backgroundColor: themeColors.primary } : { backgroundColor: themeColors.lightGrey }
        }
      >
        <FText h8 h6={isMinSM} primaryText bold>
          Done
        </FText>
      </div>
    </div>
  )
}

const classes = {
  container: css({
    height: '10rem',
    maxWidth: '90vw',
    display: 'flex',
    justifyContent: 'center',
  }),
  status: css({
    height: '8rem',
    width: '8rem',
    borderRadius: '8rem',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [MEDIA_QUERY.MIN_SM]: {
      height: '10rem',
      width: '10rem',
      borderRadius: '10rem',
    },
  }),
}

export default OrderStatusView
