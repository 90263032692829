export * from 'f-core/src/images'
export * from 'f-web-app/images'

export const logo = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/logo.png'
export const icon = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/icon.png'
export const aboutUsBG = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/aboutUsBG.png'
export const deliveryLabel =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/deliveryLabel.png'

// HERO
export const heroBG = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/heroBG.png'
export const feature1 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/feature1.png'
export const mobilePromo =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/mobilePromo.png'
export const aboutUs = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/aboutUs.png'
export const onlineOrdersBG =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/onlineOrdersBG.png'

// CONTACT US
export const contactUsMap =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/contactUsMap.png'

// REWARD
export const pointsMeter =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/rewardArrowRight.png'
export const rewardBG = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/rewardBG.png'
export const rewardWoodBG =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/rewardWoodBG.png'
export const rewardBorder =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/jejudo/rewardBorder.png'
// =======================================================
