import React, { useState, useEffect, useContext } from 'react'
import { css } from 'emotion'
import { FView, FButton, FSelect } from 'f-web-app/components'
import { dispatch } from 'f-web-app/store'
import { ThemeContext } from 'f-web-app'
import { useSelector } from 'react-redux'

const REWARD_VIEW_WIDTH = 375

export default function Rewards({ rewardImageClass }) {
  const points = useSelector(dispatch.user.getPointsWithPromoApplied)
  const rewards = useSelector(dispatch.user.getRewardsWithDetailsSorted)

  const [index, setIndex] = useState(0)
  const [pointsMeterWidth, setPointsMeterWidth] = useState(0)
  const { Cart } = useContext(ThemeContext)

  useEffect(() => {
    const pointsMeterWidth = points >= dispatch.user.getPointsMax() ? 1 : (1 * points) / dispatch.user.getPointsMax()
    setPointsMeterWidth(pointsMeterWidth)
  }, [points])

  const _handleRedeemPress = reward => {
    if (reward.valid) {
      dispatch.user.addPromo(reward.id).then(() => {
        dispatch.notification.setMessage({
          message: `${reward.name} redeemed!`,
          level: 'success',
        })
      })
    } else {
      dispatch.notification.setMessage({
        message: reward.message,
        level: 'error',
      })
    }
  }

  const reward = rewards[index] || {}
  return (
    <FView className={classes.container} shadow25>
      <Cart.RewardsLoadedView
        pointsMeterWidth={pointsMeterWidth}
        RewardImagesElement={
          <FView relative className={classes.container} overflowX={'hidden'}>
            <FView
              row
              w={REWARD_VIEW_WIDTH * rewards.length}
              style={{
                transform: `translateX(-${REWARD_VIEW_WIDTH * index}px)`,
                transition: 'all .5s cubic-bezier(.19,1,.4,1)',
              }}
            >
              {rewards.map((reward, index) => (
                <Cart.RewardItemView key={index} imageUrl={reward.imageUrl} />
              ))}
            </FView>
          </FView>
        }
        LeftArrowButtonElement={
          <FButton disabled={index <= 0} onClick={() => setIndex(index - 1)}>
            <Cart.RewardsLeftArrowButtonView disabled={index <= 0} />
          </FButton>
        }
        RightArrowButtonElement={
          <FButton disabled={index >= rewards.length - 1} onClick={() => setIndex(index + 1)}>
            <Cart.RewardsRightArrowButtonView disabled={index >= rewards.length - 1} />
          </FButton>
        }
        points={points}
        maxPoints={<FSelect selector={dispatch.user.getPointsMax} />}
        minimumSubTotal={reward.minimumSubTotal}
        requiredPoints={reward.requiredPoints}
        name={reward.name}
        reward={reward}
        RedeemRewardButtonElement={
          <FButton onClick={() => _handleRedeemPress(reward)}>
            <Cart.RewardsRedeemButtonView />
          </FButton>
        }
      />
    </FView>
  )
}

const classes = {
  container: css({
    height: 550,
    width: REWARD_VIEW_WIDTH,
  }),
  image: css({
    position: 'relative',
    height: 200,
    width: 200,
    borderRadius: 100,
    objectFit: 'cover',
  }),
  rewardDefault: css({
    position: 'relative',
    objectFit: 'contain',
    width: 120,
  }),
  rewardBorder: css({
    width: 225,
    heigth: 225,
    position: 'absolute',
  }),
}
