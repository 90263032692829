import React, { useContext } from 'react'
import { FText, FView, Space } from 'components'
import { ThemeContext } from 'f-web-app'
import { moment } from 'f-utils'

const OrderCompleteView = ({
  orderNumber,
  OrderStatusElement,
  status,
  isFoodReady,
  deliveryTime,
  orderType,
  completionTime,
  estimatedCompletionTime,
  pointsEarned,
  cancelReason,
}) => {
  const { themeColors } = useContext(ThemeContext)

  return (
    <FView fill maxWidth="100%" bg={themeColors.white} p={'2rem'} rounded shadow25>
      <FText h4 cardText textCenter>
        Order {`#${orderNumber ? orderNumber : ''}`}
      </FText>
      <Space h3 />
      {OrderStatusElement}
      <Space h3 />
      <FText h7 bold cardText textCenter>
        {status === 'Cancelled'
          ? 'Your order has been cancelled: ' + cancelReason
          : status === 'Done'
          ? 'Your order is completed! Enjoy!'
          : status === 'New'
          ? 'Your order is being confirmed by the restaurant...'
          : (status === 'Ready' || isFoodReady) && orderType !== 'Delivery'
          ? 'Your order is ready for pickup!'
          : `Your order will be ${
              orderType === 'Delivery'
                ? 'delivered at ' +
                  moment(completionTime || estimatedCompletionTime)
                    .add(deliveryTime || 15, 'minutes')
                    .format('LT')
                : 'ready for pickup at ' + moment(completionTime || estimatedCompletionTime).format('LT')
            }.`}
      </FText>
      <Space h2 />
      {status !== 'Cancelled' && pointsEarned > 0 && (
        <FText h7 bold primary textCenter>
          {`Congrats! You earned ${pointsEarned} points!`}
        </FText>
      )}
    </FView>
  )
}

export default OrderCompleteView
